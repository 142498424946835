/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "variant_default": "flex flex-col items-start gap-y-1",
  "label_variant_default": "text-body",
  "label_variant_floating": "text-body absolute top-3 left-2.5 duration-300 transform scale-75 z-10 origin-[0] -translate-y-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-1",
  "label_status_default": "peer-focus:text-primary-700",
  "label_status_error": "peer-focus:text-error-600",
  "field_status_default": "placeholder:text-gray-700 border-gray-900 focus:outline focus:outline-4 focus:outline-offset-0 focus:border-primary-700 focus:border-2 focus:bg-primary-50 focus:outline-primary-200",
  "field_status_error": "placeholder:text-gray-700 focus:placeholder:text-gray-700 focus:outline focus:outline-4 focus:outline-offset-0 border-error-600 border-2 bg-error-99 focus:outline-error-200",
  "field_variant_default": "block w-full resize-none box-border p-2.5 leading-6.5 border border-solid rounded appearance-none",
  "field_variant_floating": "block w-full resize-none box-border p-2.5 pt-7 leading-6.5 border border-solid rounded appearance-none focus:ring-0 peer placeholder:!text-transparent",
  "field_size_default": "h-35 w-55",
  "field_group_variant_default": "flex flex-col-reverse gap-y-1",
  "field_group_variant_floating": "relative",
  "caption_variant_default": "text-body",
  "caption_status_error": "text-error-600",
  "caption_group_size_default": "gap-2.5",
  "caption_group_variant_default": "flex items-center",
  "caption_group_status_error": "text-error-600"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "TextArea");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;