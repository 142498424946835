/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "variant_first": "flex gap-3 p-3 rounded",
  "variant_second": "flex gap-3 py-3 border-y-1",
  "variant_third": "flex gap-3 p-3 border-l-4 rounded-r",
  "variant_fourth": "flex gap-3 p-3 shadow-[0px_0px_10px_2px]",
  "colors_first": "bg-primary-900 text-white",
  "colors_second": "border-y-black",
  "colors_third": "bg-gray-100 border-l-primary-900",
  "colors_fourth": "shadow-black/25",
  "size_default": "not-prose",
  "group_variant_first": "flex flex-col gap-0.5",
  "group_variant_second": "flex flex-col justify-center gap-0.5",
  "group_variant_third": "flex flex-col gap-0.5",
  "group_variant_fourth": "flex flex-col gap-0.5",
  "group_colors_first": "",
  "group_colors_second": "",
  "group_colors_third": "",
  "group_colors_fourth": "",
  "group_size_default": "",
  "image_variant_first": "",
  "image_variant_second": "",
  "image_variant_third": "",
  "image_variant_fourth": "",
  "image_colors_first": "",
  "image_colors_second": "",
  "image_colors_third": "",
  "image_colors_fourth": "",
  "image_size_default": "w-35 [&_figure]:w-35",
  "category_variant_first": "",
  "category_variant_second": "hidden",
  "category_variant_third": "",
  "category_variant_fourth": "",
  "category_colors_first": "",
  "category_colors_second": "",
  "category_colors_third": "",
  "category_colors_fourth": "",
  "category_size_default": "text-body-sm",
  "description_variant_first": "",
  "description_variant_second": "",
  "description_variant_third": "",
  "description_variant_fourth": "",
  "description_colors_first": "",
  "description_colors_second": "",
  "description_colors_third": "",
  "description_colors_fourth": "",
  "description_size_default": "text-headline-sm",
  "footer_variant_first": "",
  "footer_variant_second": "",
  "footer_variant_third": "",
  "footer_variant_fourth": "",
  "footer_colors_first": "",
  "footer_colors_second": "",
  "footer_colors_third": "",
  "footer_colors_fourth": "",
  "footer_size_default": "text-headline-sm",
  "headline_variant_first": "uppercase pr-3",
  "headline_variant_second": "",
  "headline_variant_third": "uppercase",
  "headline_variant_fourth": "",
  "headline_colors_first": "bg-white",
  "headline_colors_second": "",
  "headline_colors_third": "",
  "headline_colors_fourth": "",
  "headline_size_default": "text-headline-xs",
  "line_variant_first": "border-b-2 mb-3.25",
  "line_variant_second": "hidden",
  "line_variant_third": "border-0 mb-3.25",
  "line_variant_fourth": "hidden",
  "line_colors_first": "border-primary-700",
  "line_colors_second": "",
  "line_colors_third": "",
  "line_colors_fourth": "",
  "line_size_default": "h-3.25"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "ArticleTeaserBodytext");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;