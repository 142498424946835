/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "link": {
    "variant_default": "text-center",
    "colors_default": "",
    "size_default": "text-headline-sm"
  },
  "caption_variant_default": "flex justify-center items-center normal-case uppercase",
  "caption_size_default": "text-headline-sm",
  "caption_colors_default": "",
  "icon_variant_default": "",
  "icon_size_default": "!h-3 ml-2",
  "icon_colors_default": "",
  "slide_variant_default": "relative block",
  "slide_size_default": "",
  "slide_colors_default": "",
  "playIcon_variant_default": "z-20 absolute -top-18 left-[calc(50%-20px)]",
  "playIcon_size_default": "w-10 h-10",
  "playIcon_colors_default": "text-white last:fill-primary-900",
  "header_variant_default": "flex justify-between items-center",
  "header_size_default": "",
  "header_colors_default": "",
  "title_variant_default": "uppercase",
  "title_size_default": "text-headline-sm",
  "title_colors_default": "",
  "size_default": "w-full gap-2 [&_figure]:before:w-full [&_figure]:before:h-18",
  "colors_default": "[&_figure]:before:to-black/0 [&_figure]:before:from-black/80",
  "variant_default": "relative flex flex-col [&_figure]:relative [&_figure]:before:bg-gradient-to-t [&_figure]:before:z-10 [&_figure]:before:absolute [&_figure]:before:bottom-0 [&_figure]:before:left-0"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "ShowsSlider");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;